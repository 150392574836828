export const FORM_ELEMENT_TYPES = {
    select: 'select',
    input: 'input',
    checkbox: 'checkbox',
};

export default class FormElement {
    constructor(data = {}) {
        this.label = data.label || '';
        this.type = data.type || FORM_ELEMENT_TYPES.input;
        this.default = data.default || undefined;
        this.segment = data.segment || undefined;
        this.options = data.options || [];
        this.updatesParameter = data.updatesParameter || true;
        this.visibleWhen = data.visibleWhen || [];
        this.alwaysVisible = data.alwaysVisible || true;

        this.children = (data.children || []).map(child => new FormElement(child)) || [];
    }

    addChild() {
        this.children.push(new FormElement());
    }

    deleteChild(index) {
        this.children.splice(index, 1);
    }
}
