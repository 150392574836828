<template>
    <div :key="$route.query.t"
         class="customer-container">
        <div class="header">
            <h1>{{ ucf$t("customers.customers") }}</h1>
            <router-link :to="{ name: 'customers.create', query: $route.query }"
                         class="btn primary">
                {{ ucf$t("customers.new") }}
            </router-link>
        </div>
        <div class="search-container">
            <div class="searchbar">
                <img src="/images/icons/search-icon.svg"
                     title="search-icon">
                <input :placeholder="ucf$t('global.search')"
                       name="search"
                       v-model="search">
            </div>
        </div>

        <div class="table">
            <div class="row header">
                <div class="column pointer"
                     @click="setOrderBy('name')">
                    {{ ucf$t("customers.name") }}
                </div>
                <div class="column">
                    {{ ucf$t("customers.contact") }}
                </div>
                <div class="column" />
                <div class="column" />
            </div>
            <transition-group name="list">
                <router-link :key="customer.id"
                             :to="{ name: 'customers.show', params: { customer: customer.id }, query: $route.query }"
                             class="row"
                             v-for="customer in customers">
                    <div class="column">
                        <span>
                            {{ customer.name }}
                        </span>
                    </div>
                    <div class="column">
                        <span>
                            {{ customer.contactPerson.fullName }}
                        </span>
                    </div>
                    <div class="column font-light">
                        <span>
                            {{ customer.contactPerson.email }}
                        </span>
                    </div>
                    <div class="column font-light">
                        <span>
                            {{ customer.contactPerson.phoneNumber }}
                        </span>
                    </div>
                </router-link>
            </transition-group>
            <infinite-loading :distance="400"
                              @distance="1"
                              @infinite="infiniteHandler"
                              ref="infiniteLoading"
                              spinner="spiral">
                <div class="no-results"
                     slot="no-more">
                    {{ ucf$t("customers.no_more") }}
                </div>
            </infinite-loading>
        </div>
        <router-view :key="$route.fullPath" />
    </div>
</template>

<script>
    export default {
        name: 'CustomerIndex',
        data() {
            return {
                search: '',
                page: 1,
                maxPage: 2,
                customers: [],
                orderBy: {
                    field: 'created_at',
                    sequence: 'DESC',
                },
            };
        },
        methods: {
            setOrderBy(field) {
                if (field === this.orderBy.field) {
                    this.orderBy.sequence = this.orderBy.sequence === 'DESC' ? 'ASC' : 'DESC';
                } else {
                    this.orderBy = {
                        field,
                        sequence: 'DESC',
                    };
                }
            },
            infiniteHandler($state) {
                this.$http
                    .get('/customers', {
                        page: this.page,
                        order_field: this.orderBy.field,
                        order_sequence: this.orderBy.sequence,
                        search: this.search,
                    })
                    .then(({ data }) => {
                        if (data.data.length === 0) {
                            $state.complete();
                            return;
                        }

                        data.data.forEach((customer) => {
                            this.customers.push(customer);
                        });

                        this.maxPage = data.meta.last_page;
                        $state.loaded();
                    });

                this.page = this.page + 1;
            },
        },
        watch: {
            search: {
                handler() {
                    this.customers = [];
                    this.$refs.infiniteLoading.stateChanger.reset();
                    this.page = 1;
                    this.maxPage = 2;
                },
            },
        },
    };
</script>
