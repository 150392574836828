<template>
    <base-panel @overlay-clicked="close"
                panel-class="object-formula-panel"
                ref="panel">
        <div class="object-part">
            <h1>
                {{ name }}
            </h1>
            <span class="sub-title">
                {{ $t('objects.revit_id') }}: {{ revitId }}
            </span>
            <form @submit.prevent="submit">
                <div v-for="(formula, index) in fbxObject.formulas">
                    <div class="head"
                         v-if="fbxObject.formulas.length > 0">
                        <h2 v-text="`Formule ${index + 1}`" />
                        <button @click="removeFormula(index)"
                                class="remove"
                                type="button"
                                v-if="fbxObject.formulas.length > 1">
                            <img :alt="ucf$t('objects.formula.remove')"
                                 src="/images/icons/close-icon-2.svg">
                        </button>
                    </div>
                    <div class="form-group">
                        <label for="type">
                            {{ ucf$t('objects.formula.types.label') }}
                        </label>
                        <select id="type"
                                name="type"
                                v-model="formula.formulaType">
                            <option :value="undefined"
                                    disabled>
                                {{ ucf$t('objects.formula.types.placeholder') }}
                            </option>
                            <option :value="formulaTypes.PRICE_MATRIX"
                                    v-if="priceMatrices.length > 0">
                                {{ ucf$t('objects.formula.types.price_matrix') }}
                            </option>
                            <option :value="formulaTypes.FORMULA">
                                {{ ucf$t('objects.formula.types.formula') }}
                            </option>
                            <option :value="formulaTypes.FIXED">
                                {{ ucf$t('objects.formula.types.fixed') }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group"
                         v-if="formula.formulaType === formulaTypes.FIXED">
                        <label for="fixed">
                            {{ ucf$t('objects.formula.fixed') }}
                        </label>
                        <input id="fixed"
                               name="fixed"
                               type="text"
                               v-model="formula.price">
                    </div>
                    <div class="form-group"
                         v-if="formula.formulaType === formulaTypes.FORMULA">
                        <label for="formula">
                            {{ ucf$t('objects.formula.formula') }}
                        </label>
                        <formula-input :parameters="parameters"
                                       id="formula"
                                       quick-filter=" "
                                       v-model="formula.formula" />
                    </div>
                    <div v-if="formula.formulaType === formulaTypes.PRICE_MATRIX">
                        <div class="form-group">
                            <label for="priceMatrix">
                                {{ ucf$t('objects.formula.types.label') }}
                            </label>
                            <select id="priceMatrix"
                                    name="priceMatrix"
                                    v-model="formula.priceMatrix">
                                <option :value="undefined"
                                        disabled>
                                    {{ ucf$t('objects.formula.types.placeholder') }}
                                </option>
                                <option :value="priceMatrix.id"
                                        v-for="priceMatrix of priceMatrices">
                                    {{ priceMatrix.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="widthFormula">
                                {{ ucf$t('objects.formula.width_formula') }}
                            </label>
                            <formula-input :parameters="parameters"
                                           id="widthFormula"
                                           quick-filter="breedte"
                                           v-model="formula.widthFormula" />
                        </div>
                        <div class="form-group">
                            <label for="heightFormula">
                                {{ ucf$t('objects.formula.height_formula') }}
                            </label>
                            <formula-input :parameters="parameters"
                                           id="heightFormula"
                                           quick-filter="hoogte"
                                           v-model="formula.heightFormula" />
                        </div>
                    </div>
                </div>
                <button @click="addFormula"
                        class="primary add"
                        type="button">
                    {{ ucf$t('objects.formula.add') }}
                </button>
                <button class="primary">
                    {{ ucf$t('objects.formula.store') }}
                </button>
            </form>
        </div>
    </base-panel>
</template>

<script>
    import BasePanel from './BasePanel';
    import FormulaInput from './FormulaInput';

    function newFormula() {
        return {
            formula: '',
            widthFormula: '',
            heightFormula: '',
            price: '',
        };
    }

    export default {
        name: 'ObjectFormulaPanel',
        components: { FormulaInput, BasePanel },
        data() {
            return {
                priceMatrices: [],
                fbxObject: {
                    name: 'Symbol_Name_0000001',
                    formulaType: undefined,
                    formulas: [
                        newFormula(),
                    ],
                },
            };
        },
        methods: {
            open(object) {
                this.fbxObject = {
                    id: object.object.ID,
                    uuid: object.object.uuid,
                    name: object.object.name,
                    reference: object,
                    formulas: this.empty(object.formulas) ? [newFormula()] : object.formulas,
                };

                this.$refs.panel.open();
            },
            close() {
                this.$refs.panel.close();
                setTimeout(() => {
                    this.fbxObject = {
                        name: 'Symbol_Name_0000001',
                        widthFormula: [],
                        heightFormula: [],
                    };
                }, 1000);
            },
            addFormula() {
                this.fbxObject.formulas.push(newFormula());
            },
            removeFormula(index) {
                this.fbxObject.formulas.splice(index, 1);
            },
            isValid(formula) {
                switch (formula.formulaType) {
                case this.formulaTypes.PRICE_MATRIX:
                    return !this.empty(formula.heightFormula) && !this.empty(formula.widthFormula);
                case this.formulaTypes.FORMULA:
                    return !this.empty(formula.formula);
                case this.formulaTypes.FIXED:
                    return !this.empty(formula.price);
                default:
                    return false;
                }
            },
            submit() {
                if (this.formulas.every(this.isValid)) {
                    this.$emit('submit', { reference: this.fbxObject.reference, formulas: this.formulas });
                    this.close();
                    return;
                }

                this.$flash.flash(this.$t('objects.formula.invalid'));
            },
        },
        computed: {
            name: {
                get() {
                    return this.fbxObject.name.split('_')[1];
                },
            },
            revitId: {
                get() {
                    return this.fbxObject.name.split('_')[2];
                },
            },
            symbol: {
                get() {
                    return this.fbxObject.name.split('_')[0];
                },
            },
            parameters: {
                get() {
                    return (this.object.parameters || '').split(',');
                },
            },
            formulas: {
                get() {
                    const { fbxObject, revitId } = this;

                    return fbxObject.formulas.map(formula => ({
                        revitId,
                        ...formula,
                    }));
                },
            },
        },
        beforeMount() {
            this.$http.get('/price-matrices')
                .then((response) => {
                    this.priceMatrices = response.data.data;
                });
        },
    };
</script>
