<template>
    <base-panel @overlay-clicked="close"
                class="sidebar-container"
                panel-class="sidebar"
                ref="panel">
        <div class="header">
            <h1>
                {{ ucf$t('objects.new') }}
            </h1>
        </div>
        <form class="body"
              ref="employeeForm"
              @submit.prevent="submit">
            <h2>
                {{ ucf$t('settings.general_data') }}
            </h2>
            <div class="form-group">
                <label for="companyName">
                    {{ ucf$t("objects.name") }}
                </label>
                <input id="companyName"
                       name="companyName"
                       ref="companyName"
                       type="text"
                       v-model="object.name">
            </div>
            <div class="form-group">
                <file-input :default="null"
                            class="new-object-file-input"
                            file-type="image/*"
                            v-model="object.preview">
                    <template #default="{image}">
                        <div class="object-image">
                            <img :src="image"
                                 alt="Image"
                                 v-if="image">
                            <img :alt="object.name"
                                 :src="object.previewUrl"
                                 v-else-if="!!object.previewUrl">
                            <div class="img-placeholder"
                                 v-else />
                            <span>
                                {{ ucf$t('objects.add_image') }}
                            </span>
                        </div>
                    </template>
                </file-input>
            </div>
            <div class="form-group">
                <file-input :create-base64="false"
                            :default="null"
                            class="new-object-file-input"
                            file-type=".rfa"
                            v-model="object.revitFile">
                    <template #default="{image}">
                        <div class="object-image">
                            <img alt="Checkmark Icon"
                                 class="checkmark"
                                 src="/images/icons/check-pink-icon.svg"
                                 v-if="image || !!object.sourceUrl">
                            <div class="img-placeholder"
                                 v-else />
                            <span>
                                {{ ucf$t('objects.upload_revit_file') }}
                            </span>
                        </div>
                    </template>
                </file-input>
            </div>
            <div class="form-group">
                <file-input :create-base64="false"
                            :default="null"
                            class="new-object-file-input"
                            file-type=".fbx"
                            v-model="object.fbxFile">
                    <template #default="{image}">
                        <div class="object-image">
                            <img alt="Checkmark Icon"
                                 class="checkmark"
                                 src="/images/icons/check-pink-icon.svg"
                                 v-if="image || !!object.sourceUrl">
                            <div class="img-placeholder"
                                 v-else />
                            <span>
                                {{ ucf$t('objects.upload_fbx_file') }}
                            </span>
                        </div>
                    </template>
                </file-input>
            </div>

            <button class="primary">
                {{ ucf$t('objects.new') }}
            </button>
        </form>
    </base-panel>
</template>

<script>
    import moment from 'moment';
    import BasePanel from '../components/BasePanel';
    import FileInput from '../components/FileInput';

    export default {
        name: 'ObjectCreate',
        components: { FileInput, BasePanel },
        data() {
            return {
                object: {},
            };
        },
        methods: {
            submit() {
                const formData = new FormData();

                Object.entries(this.object).forEach(([key, value]) => formData.append(key, value));

                this.$http.post('/revit-objects/create', formData)
                    .then(() => {
                        this.$router.replace({
                            name: 'objects.index',
                            params: this.$route.params,
                            query: {
                                ...this.$route.query,
                                t: moment().format('HHmmssSSS'),
                            },
                        });
                    })
                    .catch(error => this.handleErrors(error.response));
            },
            close() {
                this.$router.replace({
                    name: 'objects.index',
                    query: this.$route.query,
                });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
