<template>
    <div :key="$route.params.status"
         class="parameter-container">
        <div class="header">
            <h1>
                {{ $t('Create frame') }}
            </h1>
        </div>
        <div class="tabs">
            <router-link :to="{ name: 'frames.create.general' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('General') }}
            </router-link>
            <router-link :to="{ name: 'frames.create.parameters' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Parameters') }}
            </router-link>
            <router-link :to="{ name: 'frames.create.formulas' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Formulas') }}
            </router-link>
            <router-link :to="{ name: 'frames.create.models' }"
                         active-class=""
                         class="tab-item"
                         exact-active-class="active">
                {{ $t('Models') }}
            </router-link>
        </div>

        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'FrameCreate',
        data() {
            return {
                frame: { parameters: [], formulas: [], },
                revitOptions: {},
                parameters: [],
            };
        },
        methods: {
            store() {

            },
        },
        mounted() {
            this.$http.get('/parameters')
                .then((response) => {
                    this.parameters = response.data.data;
                });
        },
    };
</script>
