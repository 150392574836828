import moment from 'moment';
import { Authenticator } from '../configuration/auth';
import { auth as authConfig } from '../configuration/config';
import { empty } from '../configuration/methods';
import { roles } from '../configuration/variables';
import CustomerCreate from '../views/CustomerCreate';
import CustomerEdit from '../views/CustomerEdit';
import CustomerGeneral from '../views/CustomerGeneral';
import CustomerIndex from '../views/CustomerIndex';
import CustomerPayment from '../views/CustomerPayment';
import CustomerShowStaff from '../views/CustomerShowStaff';
import CustomerShowStaffCreate from '../views/CustomerShowStaffCreate';
import CustomerShowStaffEdit from '../views/CustomerShowStaffEdit';
import FrameCreate from '../views/FrameCreate';
import FrameFormulas from '../views/FrameFormulas';
import FrameGeneral from '../views/FrameGeneral';
import FrameParameters from '../views/FrameParameters';
import Login from '../views/Login';
import NotificationCreate from '../views/NotificationCreate';
import NotificationIndex from '../views/NotificationIndex';
import ObjectCreate from '../views/ObjectCreate';
import ObjectEdit from '../views/ObjectEdit';
import ObjectIndex from '../views/ObjectIndex';
import ObjectParameters from '../views/ObjectParameters';
import OnboardingShow from '../views/OnboardingShow';
import ParameterCreate from '../views/ParameterCreate';
import ParameterEdit from '../views/ParameterEdit';
import ParameterForm from '../views/ParameterForm';
import ParameterIndex from '../views/ParameterIndex';
import ParameterInfo from '../views/ParameterInfo';
import ParameterSegment from '../views/ParameterSegment';
import PasswordForgot from '../views/PasswordForgot';
import PasswordReset from '../views/PasswordReset';
import PriceMatrixCreate from '../views/PriceMatrixCreate';
import PriceMatrixEdit from '../views/PriceMatrixEdit';
import PriceMatrixIndex from '../views/PriceMatrixIndex';
import ProfileShow from '../views/ProfileShow';
import ProjectApprove from '../views/ProjectApprove';
import ProjectCancel from '../views/ProjectCancel';
import ProjectCreate from '../views/ProjectCreate';
import ProjectEdit from '../views/ProjectEdit';
import ProjectIndex from '../views/ProjectIndex';
import ProjectNotifications from '../views/ProjectNotifications';
import ProjectReopen from '../views/ProjectReopen';
import ProjectShare from '../views/ProjectShare';
import ProjectShareInvite from '../views/ProjectShareInvite';
import ProjectShareSharedWith from '../views/ProjectShareSharedWith';
import ProjectShareSharedWithDelete from '../views/ProjectShareSharedWithDelete';
import ProjectShareSharedWithTransfer from '../views/ProjectShareSharedWithTransfer';
import ProjectShow from '../views/ProjectShow';
import Register from '../views/Register';
import SettingGeneral from '../views/SettingGeneral';
import SettingIndex from '../views/SettingIndex';
import SettingStaff from '../views/SettingStaff';
import SettingStaffCreate from '../views/SettingStaffCreate';
import SettingStaffEdit from '../views/SettingStaffEdit';
import { auth, guest, role } from './middleware';


export default [
    // Default redirect
    {
        path: '/',
        redirect: '/projects',
    },

    // Project routes
    {
        name: 'projects.index',
        path: '/projects/:status?',
        component: ProjectIndex,
        children: [
            {
                name: 'projects.create',
                path: 'create',
                component: ProjectCreate,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN, true),
                    ],
                    noBinding: ['status'],
                    forbiddenRoute: { name: 'projects.index' },
                },
            },
        ],
        meta: {
            noBinding: ['status'],
            middleware: [
                auth,
                ({ to, next }) => {
                    if (!to.params.status) {
                        return next({ name: 'projects.index', params: { status: 1 } });
                    }

                    return next();
                },
            ],
            forbiddenRoute: { name: 'projects.index' },
        },
    },
    {
        name: 'projects.show',
        path: '/projects/:project/show',
        component: ProjectShow,
        children: [
            {
                name: 'projects.approve',
                path: '/projects/:project/approve',
                component: ProjectApprove,
                meta: {
                    middleware: [auth, role(roles.OU_APPROVE_QUOTATION, true)],
                },
            },
            {
                name: 'projects.edit',
                path: '/projects/:project/edit',
                component: ProjectEdit,
                meta: {
                    middleware: [auth],
                },
            },
            {
                name: 'projects.cancel',
                path: '/projects/:project/cancel',
                component: ProjectCancel,
                meta: {
                    middleware: [auth],
                },
            },
            {
                name: 'projects.reopen',
                path: '/projects/:project/reopen',
                component: ProjectReopen,
                meta: {
                    middleware: [auth],
                },
            },
            {
                name: 'projects.notifications',
                path: '/projects/:project/notifications',
                component: ProjectNotifications,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                },
            },
            {
                name: 'projects.share',
                path: 'share',
                component: ProjectShare,
                children: [
                    {
                        name: 'projects.share.invite',
                        path: 'invite',
                        component: ProjectShareInvite,
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                        },
                    },
                    {
                        name: 'projects.share.shared-with',
                        path: 'shared-with',
                        component: ProjectShareSharedWith,
                        children: [
                            {
                                name: 'projects.share.shared-with.transfer',
                                path: ':user/transfer',
                                component: ProjectShareSharedWithTransfer,
                                meta: {
                                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                                },
                            },
                            {
                                name: 'projects.share.shared-with.delete',
                                path: ':user/delete',
                                component: ProjectShareSharedWithDelete,
                                meta: {
                                    noBinding: ['user'],
                                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                                },
                            },
                        ],
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                        },
                    },
                ],
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN, true),
                        ({ to, next }) => {
                            if (to.name === 'projects.share') {
                                return next({
                                    name: 'projects.share.invite',
                                    params: to.params,
                                    query: to.query,
                                });
                            }
                            return next();
                        },
                    ],
                },
            },
        ],
        meta: {
            bindingRedirect: 'projects.index',
            middleware: [auth],
        },
    },

    // Settings routes
    {
        name: 'settings.index',
        path: '/settings',
        redirect: '/settings/general',
        component: SettingIndex,
        children: [
            {
                name: 'settings.general',
                path: 'general',
                component: SettingGeneral,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                },
            },
            {
                name: 'settings.staff',
                path: 'staff',
                component: SettingStaff,
                children: [
                    {
                        name: 'settings.staff.create',
                        path: 'create',
                        component: SettingStaffCreate,
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                        },
                    },
                    {
                        name: 'settings.staff.edit',
                        path: ':staffUser/edit',
                        component: SettingStaffEdit,
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN, true), role(roles.OU_USER_MANAGEMENT)],
                            bindingUrls: {
                                staffUser: 'customers/me/users/:staffUser',
                            },
                        },
                    },
                ],
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
                },
            },
        ],
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN, true)],
        },
    },

    // Profile route
    {
        name: 'profile.show',
        path: '/profile',
        component: ProfileShow,
        meta: {
            middleware: [auth],
        },
    },

    // Customer routes
    {
        name: 'customers.index',
        path: '/customers',
        component: CustomerIndex,
        children: [
            {
                name: 'customers.create',
                path: 'create',
                component: CustomerCreate,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN)],
        },
    },
    {
        name: 'customers.show',
        path: '/customers/:customer',
        component: CustomerEdit,
        children: [
            {
                name: 'customers.show.general',
                path: 'general',
                component: CustomerGeneral,
                meta: {
                    bindingUrls: {
                        customer: '/customers/:customer/show',
                    },
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'customers.show.staff',
                path: 'staff',
                component: CustomerShowStaff,
                children: [
                    {
                        name: 'customers.show.staff.create',
                        path: 'create',
                        component: CustomerShowStaffCreate,
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN)],
                            bindingUrls: {
                                staffUser: 'customers/me/users/:staffUser',
                                customer: '/customers/:customer/show',
                            },
                        },
                    },
                    {
                        name: 'customers.show.staff.edit',
                        path: ':staffUser/edit',
                        component: CustomerShowStaffEdit,
                        meta: {
                            middleware: [auth, role(roles.GLOBAL_ADMIN)],
                            bindingUrls: {
                                staffUser: 'customers/me/users/:staffUser',
                                customer: '/customers/:customer/show',
                            },
                        },
                    },
                ],
                meta: {
                    bindingUrls: {
                        customer: '/customers/:customer/show',
                    },
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'customers.show.payment',
                path: 'payment',
                component: CustomerPayment,
                meta: {
                    bindingUrls: {
                        customer: '/customers/:customer/show',
                    },
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            bindingUrls: {
                customer: '/customers/:customer/show',
            },
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => {
                    if (to.name === 'customers.show') {
                        return next({
                            name: 'customers.show.general',
                            params: to.params,
                            query: to.query,
                        });
                    }

                    return next();
                },
            ],
        },
    },

    // Notification routes
    {
        name: 'notifications.index',
        path: '/notifications',
        component: NotificationIndex,
        children: [
            {
                name: 'notifications.create',
                path: 'create',
                component: NotificationCreate,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [auth],
        },
    },

    // Object routes
    {
        name: 'objects.index',
        path: '/objects',
        component: ObjectIndex,
        children: [
            {
                name: 'objects.create',
                path: 'create',
                component: ObjectCreate,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
        ],
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN)],
        },
    },
    {
        name: 'objects.edit',
        path: '/objects/:object',
        component: ObjectEdit,
        children: [
            {
                name: 'objects.edit.parameters',
                path: 'parameters',
                component: ObjectParameters,
                meta: {
                    bindingUrls: {
                        object: '/revit-objects/:object',
                    },
                    bindingRedirect: 'objects.index',
                    middleware: [auth],
                },
            },
        ],
        meta: {
            bindingUrls: {
                object: '/revit-objects/:object',
            },
            bindingRedirect: 'objects.index',
            middleware: [auth],
        },
    },

    // Frame routes
    {
        name: 'frames.create',
        path: '/frames/create',
        component: FrameCreate,
        children: [
            {
                name: 'frames.create.general',
                path: 'general',
                component: FrameGeneral,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'frames.create.parameters',
                path: 'parameters',
                component: FrameParameters,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'frames.create.formulas',
                path: 'formulas',
                component: FrameFormulas,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                        ({to, from, next}) => {
                            if (from.name === null && to.name.includes('create')) {
                                return next({ name: 'frames.create.general' });
                            }

                            return next();
                        }
                    ],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                // ({ next }) => {
                //     return next({ name: 'frames.create.general' });
                // },
            ],
        },
    },

    // Parameter routes
    {
        name: 'parameters.index',
        path: '/parameters',
        component: ParameterIndex,
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
            ],
        },
    },
    {
        name: 'parameters.create',
        path: '/parameters/create',
        component: ParameterCreate,
        children: [
            {
                name: 'parameters.create.info',
                path: 'info',
                component: ParameterInfo,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'parameters.create.form',
                path: 'form',
                component: ParameterForm,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'parameters.create.segments',
                path: 'segments',
                component: ParameterSegment,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                        ({ from, next }) => {
                            if (['parameters.create.info', 'parameters.create.segments'].includes(from.name)) {
                                return next();
                            }

                            return next({ name: 'parameters.create.info' });
                        },
                    ],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ next }) => next({ name: 'parameters.create.info' }),
            ],
        },
    },
    {
        name: 'parameters.edit',
        path: '/parameters/:parameter/edit',
        component: ParameterEdit,
        children: [
            {
                name: 'parameters.edit.info',
                path: 'info',
                component: ParameterInfo,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'parameters.edit.form',
                path: 'form',
                component: ParameterForm,
                meta: {
                    middleware: [auth, role(roles.GLOBAL_ADMIN)],
                },
            },
            {
                name: 'parameters.edit.segments',
                path: 'segments',
                component: ParameterSegment,
                meta: {
                    middleware: [
                        auth,
                        role(roles.GLOBAL_ADMIN),
                    ],
                },
            },
        ],
        meta: {
            middleware: [
                auth,
                role(roles.GLOBAL_ADMIN),
                ({ to, next }) => next({ name: 'parameters.edit.info', params: to.params, query: to.query }),
            ],
        },
    },

    // Price matrix routes
    {
        name: 'price-matrices.index',
        path: '/price-matrices',
        component: PriceMatrixIndex,
        children: [
            {
                name: 'price-matrices.create',
                path: 'create',
                component: PriceMatrixCreate,
            },
            {
                name: 'price-matrices.edit',
                path: ':priceMatrix/edit',
                component: PriceMatrixEdit,
            },
        ],
        meta: {
            middleware: [auth, role(roles.GLOBAL_ADMIN)],
        },
    },

    // Onboarding route
    {
        name: 'onboarding.show',
        path: '/onboarding',
        component: OnboardingShow,
        meta: {
            middleware: [auth],
        },
    },

    // Auth routes
    {
        name: 'auth.login',
        path: '/login',
        component: Login,
        meta: {
            middleware: [guest],
        },
    },
    {
        name: 'auth.register',
        path: '/register',
        component: Register,
        meta: {
            middleware: [guest],
        },
    },
    {
        name: 'auth.logout',
        path: '/logout',
        meta: {
            middleware: [auth],
        },
    },
    {
        name: 'auth.sso',
        path: '/sso',
        meta: {
            middleware: [
                ({ to, next }) => {
                    const { accessToken, refreshToken } = to.query;
                    const expiresAt = moment().add(3, 'hours').format('YYYY-MM-DD HH:mm:ss');

                    const Auth = new Authenticator(authConfig);

                    Auth._set('token', accessToken);
                    Auth._set('refreshToken', refreshToken);
                    Auth._set('expiresAt', expiresAt);

                    if (!empty(to.query.redirectTo)) {
                        next(to.query.redirectTo);
                        return;
                    }

                    next('/');
                },
            ],
        },
    },
    {
        name: 'password.forgot',
        path: '/password/forgot',
        component: PasswordForgot,
        meta: {
            middleware: [guest],
        },
    },
    {
        name: 'password.reset',
        path: '/password/reset/:token',
        component: PasswordReset,
        meta: {
            middleware: [guest],
            noBinding: ['token'],
        },
    },
];
