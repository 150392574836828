<template>
    <div class="customer-container">
        <div class="header">
            <h1>{{ ucf$t("parameters.parameters") }}</h1>
            <router-link :to="{ name: 'parameters.create' }"
                         class="btn primary"
                         tag="button">
                {{ ucf$t("parameters.new") }}
            </router-link>
        </div>

        <div class="table">
            <div class="row header">
                <div class="column pointer">
                    {{ ucf$t("parameters.name") }}
                </div>
            </div>
            <transition-group name="list">
                <router-link :key="parameter.id"
                             :to="{ name: 'parameters.edit', params: { parameter: parameter.id }, query: $route.query }"
                             class="row"
                             v-for="parameter in parameters">
                    <div class="column">
                        <span>
                            {{ parameter.name }}
                        </span>
                    </div>
                </router-link>
            </transition-group>
            <infinite-loading :distance="400"
                              @distance="1"
                              @infinite="infiniteHandler"
                              ref="infiniteLoading"
                              spinner="spiral">
                <div class="no-results"
                     slot="no-more">
                    {{ ucf$t("parameters.no_more") }}
                </div>
            </infinite-loading>
        </div>
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'ParameterIndex',
        data() {
            return {
                page: 1,
                maxPage: 2,
                parameters: [],
            };
        },
        methods: {
            infiniteHandler($state) {
                if (this.page <= this.maxPage) {
                    this.$http.get(`parameters?page=${this.page}`)
                        .then((response) => {
                            const { data } = response;
                            this.parameters = [...this.parameters, ...data.data];
                            this.maxPage = data.meta.last_page;
                            $state.loaded();
                        });

                    this.page = this.page + 1;
                } else {
                    $state.complete();
                }
            },
        },
        beforeRouteUpdate(to, from, next) {
            this.objects = [];
            this.page = 1;
            this.maxPage = 2;
            this.search = to.query.search;
            this.orderBy = {
                field: to.query.orderField,
                sequence: to.query.orderSequence,
            };

            setTimeout(() => {
                this.$refs.infiniteLoading.stateChanger.reset();
            }, 500);

            return next();
        },
    };
</script>
