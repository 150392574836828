<template>
    <base-panel ref="panel"
                @overlay-clicked="close">
        <div class="header">
            {{ ucf$t("projects.approve") }}
            <div class="close"
                 @click="close">
                <img alt="close"
                     src="/images/icons/close-icon.svg">
            </div>
        </div>
        <div class="body">
            <div :class="{'active' : !project.hasCancellation}"
                 class="approve-option"
                 @click="setCancellation(false)">
                <span class="checkbox" />
                <div class="text-container">
                    <span class="amount">
                        &euro; {{ numberFormat(totalPriceWithoutCancellation) }}
                    </span>
                    <span class="description">
                        {{ ucf$t("projects.without_cancellation") }}
                    </span>
                </div>
            </div>

            <div :class="{'active' : project.hasCancellation}"
                 class="approve-option"
                 @click="setCancellation(true)">
                <span class="checkbox" />
                <div class="text-container">
                    <span class="amount">
                        &euro; {{ numberFormat(totalPriceWithCancellation) }}
                    </span>
                    <span class="description">
                        {{ ucf$t("projects.with_cancellation") }}
                    </span>
                    <span class="sub-description">
                        {{ ucf$t("projects.cancellation_days") }}
                    </span>
                </div>
            </div>
        </div>
        <div class="footer">
            <button class="primary"
                    v-if="showApproveAndPay"
                    @click="approveAndPay">
                {{ ucf$t("projects.approve_and_pay") }}
            </button>
            <button class="primary"
                    v-else
                    @click="approveProject">
                {{ ucf$t("projects.approve") }}
            </button>
        </div>
    </base-panel>
</template>

<script>
    import BasePanel from '../components/BasePanel';

    export default {
        name: 'ProjectApprove',
        components: { BasePanel },
        methods: {
            close() {
                this.$router.replace({
                    name: 'projects.show',
                    params: { project: this.project.id },
                    query: this.$route.query,
                });
            },
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
