import debounce from 'lodash/debounce';
import moment from 'moment';
import Vue from 'vue';
import { Flashes } from 'vue-flashes';
import VueRouter from 'vue-router';
import { resolveBinding } from '../configuration/methods';
import i18n from '../trans';
import { executeMiddleware } from './middleware';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact',
    routes,
});

router.beforeEach((to, from, next) => executeMiddleware(to, from, next, router));

const bindables = [];

function getBindables(route) {
    (route.children || []).forEach(getBindables);

    if (!route.path.includes(':')) {
        return;
    }

    const split = route.path.split('/').filter(part => part.startsWith(':'));
    split.forEach((part) => {
        bindables.push(part.substr(1));
    });
}

router.options.routes.forEach(getBindables);

router.beforeEach(
    debounce((to, from, next) => {
        const noBinding = to.meta.noBinding || [];
        const params = Object.entries(to.params);
        const promiseArray = [];
        const { matched } = to;

        if (typeof matched[matched.length - 1] !== 'undefined' && matched[matched.length - 1].name !== to.name) {
            return;
        }

        params.forEach(([entity, id]) => {
            if (!bindables.includes(entity) || noBinding.includes(entity.substr(1))) {
                return;
            }

            promiseArray.push(
                resolveBinding(entity, id, to.query.t || moment().format('HHmmssSSS'), to.meta.bindingUrls || {})
                    .then(result => ({
                            [entity]: result,
                        }))
                    .catch((error) => {
                        error.entity = entity;
                        return error;
                    }),
            );
        });

        Promise.all(promiseArray)
            .then((results) => {
                results.forEach((result) => {
                    Object.entries(result).forEach(([entity, value]) => {
                        Vue.prototype[entity] = value;
                    });
                });
                next();
            })
            .catch((error) => {
                if (typeof error.response === 'undefined') {
                    throw error;
                }

                if (error.response.status === 404) {
                    Flashes.flash(
                        i18n.t(`bindings.not_found.${error.entity}`),
                        'error',
                    );
                    next({ name: to.meta.bindingRedirect || '/' });
                    return;
                }
                if (error.response.status === 403) {
                    next(from);
                }
            });
    }, 300),
);

export default router;
