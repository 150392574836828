<template>
    <div class="parameter-form-form">
        <form-element :form-element="formElement"
                      :title="ucf$t('parameters.form-element')"
                      root />
    </div>
</template>

<script>
    import FormElement from '../models/FormElement';
    import FormElementComponent from '../components/FormElement';

    export default {
        name: 'ParameterForm',
        components: { 'form-element': FormElementComponent },
        computed: {
            parameter: {
                get() {
                    return this.$parent.parameter;
                },
            },
            formElement: {
                get() {
                    const { formElement } = this.$parent.parameter;

                    if (formElement instanceof FormElement) {
                        return formElement;
                    }

                    return new FormElement(formElement);
                },
            },
            segments: {
                get() {
                    return this.$parent.parameter.segments;
                },
            },
            showSegments: {
                get() {
                    return this.segments.length > 1;
                },
            },
        },
    };
</script>
