<template>
    <base-panel @overlay-clicked="close"
                panel-class="modal object-parameter-modal"
                ref="panel">
        <div class="header">
            <h1>
                {{ ucf$t("objects.edit-parameters") }}
            </h1>
            <div class="close"
                 @click="close">
                <img alt="close"
                     src="/images/icons/close-icon.svg">
            </div>
        </div>
        <div class="body">
            <div class="form-group">
                <label for="options">
                    {{ ucf$t("objects.choose-parameters") }}
                </label>
                <vue-multiselect :close-on-select="false"
                                 :options="options"
                                 :placeholder="ucf$t('parameters.forms.select-options')"
                                 class="w-1/4 mr-4"
                                 deselect-label=""
                                 id="options"
                                 label="name"
                                 multiple
                                 name="options[]"
                                 select-label=""
                                 selected-label=""
                                 show-labels
                                 track-by="id"
                                 v-model="parameters" />
            </div>
            <vue-draggable :disabled="false"
                           :list="parameters"
                           @end="dragging = false"
                           @start="dragging = true"
                           animation="200"
                           class="parameters"
                           ghost-class="ghost"
                           group="description">
                <transition-group :name="!dragging ? 'flip-list' : null"
                                  type="transition">
                    <div :key="parameter.id"
                         class="list-group-item"
                         v-for="parameter in parameters">
                        {{ parameter.name }}
                    </div>
                </transition-group>
            </vue-draggable>
        </div>
    </base-panel>
</template>

<script>
    import VueMultiselect from 'vue-multiselect';
    import VueDraggable from 'vuedraggable';
    import BasePanel from '../components/BasePanel';

    export default {
        name: 'ObjectParameters',
        components: { BasePanel, VueDraggable, VueMultiselect },
        data() {
            return {
                dragging: false,
                options: [],
                parameters: [],
            };
        },
        methods: {
            close() {
                this.$router.replace({
                    ...this.$route,
                    name: 'objects.edit',
                });
            },
        },
        watch: {
            parameters: {
                handler(parameters) {
                    this.object.parameters = parameters;

                    this.$http.put(`/revit-objects/${this.object.id}/parameters`, { parameters: parameters.map(parameter => parameter.id) })
                        .catch(error => this.handleErrors(error.response));
                },
                deep: true,
            },
        },
        beforeMount() {
            this.$http.get('/parameters')
                .then((response) => {
                    this.options = response.data.data;
                });
        },
        mounted() {
            this.$refs.panel.open();
        },
        beforeRouteEnter(to, from, next) {
            return next((vm) => {
                vm.parameters = vm.object.parameters;

                return next();
            });
        },
        beforeRouteUpdate(to, from, next) {
            this.parameters = this.object.parameters;

            return next();
        },
        beforeRouteLeave(to, from, next) {
            this.$refs.panel.close();
            return next();
        },
    };
</script>
