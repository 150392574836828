import Vue from 'vue';
import Api from '../configuration/api';
import { Authenticator } from '../configuration/auth';
import { auth as authConfig } from '../configuration/config';
import { empty } from '../configuration/methods';

function nextFactory(context, middleware, index) {
    const nextMiddleware = middleware[index];

    if (!nextMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);

        const next = nextFactory(context, middleware, index + 1);
        nextMiddleware({
            ...context,
            next,
        });
    };
}

async function loadProfile() {
    Vue.prototype.$user = JSON.parse(localStorage.getItem('webo_bim_user_data'));

    return Api.get('/users/current')
        .then((response) => {
            Vue.prototype.$user = response.data.data;
            localStorage.setItem(
                'webo_bim_user_data',
                JSON.stringify(Vue.prototype.$user),
            );
        })
        .catch((error) => {
            if (error.response.status === 401) {
                localStorage.removeItem('webo_bim_token');
                localStorage.removeItem('webo_bim_user_data');
                localStorage.removeItem('webo_bim_expires_at');
                localStorage.removeItem('webo_bim_refresh_token');
            }
            return error;
        });
}

export function auth({ to, next, authenticator }) {
    if (to.name === 'auth.logout') {
        authenticator.logout();
        localStorage.removeItem('webo_bim_user_data');
        next(authConfig.options.redirects.login);
        return;
    }

    authenticator.check()
        .then(() => {
            loadProfile().then(next)
                .catch(() => {
                    next(authConfig.options.redirects.failed);
                });
        })
        .catch(() => {
            next(authConfig.options.redirects.failed);
        });
}

export function guest({ next, authenticator }) {
    authenticator.check()
        .then(() => {
            next(authConfig.options.redirects.success);
        })
        .catch(() => {
            next();
        });
}

export function role(_roles, invert = false) {
    let roles = _roles;

    if (!Array.isArray(_roles)) {
        roles = [_roles];
    }

    return ({ from, next }) => {
        if (!Vue.prototype.$user) {
            next(from);
            return;
        }

        const intersection = Vue.prototype.$user.roles.filter(r => roles.includes(r.id));

        if ((intersection.length === 0) !== invert) {
            next(from);
            return;
        }

        next();
    };
}

export function executeMiddleware(to, from, next, router) {
    if (!empty(to.meta.middleware)) {
        let { middleware } = to.meta;

        if (!Array.isArray(middleware)) {
            middleware = [middleware];
        }

        const context = {
            to, from, router, next, redirect: next, authenticator: new Authenticator(authConfig),
        };

        return middleware[0]({
            ...context,
            next: nextFactory(context, middleware, 1),
        });
    }

    return next();
}
