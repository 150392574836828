<template>
    <div :key="$route.query.t"
         class="object-detail-container">
        <form @change.prevent="submit"
              @submit.prevent="submit"
              class="body detail">
            <div class="object">
                <div class="header">
                    <click-to-input :modifier="ucfirst"
                                    class="name"
                                    tag="h1"
                                    v-model="object.name" />
                    <router-link :to="{...$route, name: 'objects.edit.parameters'}"
                                 class="secondary"
                                 tag="button"
                                 type="button">
                        {{ ucf$t('objects.edit-parameters') }}
                    </router-link>
                </div>
            </div>
            <div class="form-group">
                <file-input :default="null"
                            class="new-object-file-input"
                            file-type="image/*"
                            v-model="object.preview">
                    <template #default="{image}">
                        <div class="object-image">
                            <img :src="image"
                                 alt="Image"
                                 v-if="image">
                            <img :alt="object.name"
                                 :src="object.previewUrl"
                                 v-else-if="!!object.previewUrl">
                            <div class="img-placeholder"
                                 v-else />
                            <span>
                                {{ ucf$t('objects.add_image') }}
                            </span>
                        </div>
                    </template>
                </file-input>
            </div>
            <div class="form-group">
                <file-input :create-base64="false"
                            :default="null"
                            class="new-object-file-input"
                            file-type=".rfa"
                            v-model="object.revitFile">
                    <template #default="{image}">
                        <div class="object-image">
                            <img alt="Checkmark Icon"
                                 class="checkmark"
                                 src="/images/icons/check-pink-icon.svg"
                                 v-if="image || !!object.sourceUrl">
                            <div class="img-placeholder"
                                 v-else />
                            <span>
                                {{ ucf$t('objects.upload_revit_file') }}
                            </span>
                        </div>
                    </template>
                </file-input>
            </div>
            <div class="form-group">
                <file-input :create-base64="false"
                            :default="null"
                            class="new-object-file-input"
                            file-type=".fbx"
                            v-model="object.fbxFile">
                    <template #default="{image}">
                        <div class="object-image">
                            <img alt="Checkmark Icon"
                                 class="checkmark"
                                 src="/images/icons/check-pink-icon.svg"
                                 v-if="image || !!object.sourceUrl">
                            <div class="img-placeholder"
                                 v-else />
                            <span>
                                {{ ucf$t('objects.upload_fbx_file') }}
                            </span>
                        </div>
                    </template>
                </file-input>
            </div>
        </form>
        <div class="overview">
            <fbx-viewer :background="0xF8E8F1"
                        :fbx="`${object.fbxUrl}?t=${object.updatedAt}`"
                        :highlight-color="0xFE81C1"
                        :key="updatedAt"
                        @object-click="onObjectClicked"
                        @object-loaded="onObjectLoaded"
                        ref="fbxViewer" />
        </div>

        <object-formula-panel @submit="storeObjectFormula"
                              ref="objectFormulaPanel" />

        <router-view />
    </div>
</template>

<script>
    import moment from 'moment';
    import ClickToInput from '../components/ClickToInput';
    import FbxViewer from '../components/FbxViewer';
    import FileInput from '../components/FileInput';
    import ObjectFormulaPanel from '../components/ObjectFormulaPanel';

    export default {
        name: 'ObjectEdit',
        components: {
            ObjectFormulaPanel, ClickToInput, FileInput, FbxViewer,
        },
        methods: {
            submit() {
                const formData = new FormData();

                formData.append('name', this.object.name);
                formData.append('parameters', this.object.parameters);

                Object.entries(this.object).forEach(([field, value]) => {
                    if (value instanceof File) {
                        formData.append(field, value);
                    }
                });

                this.$http.patch(`/revit-objects/${this.object.id}`, formData)
                    .then(() => {
                        this.$router.replace({
                            ...this.$route,
                            query: {
                                ...this.$route.query,
                                t: moment().format('HHmmssSSS'),
                            },
                        });
                    })
                    .catch(error => this.handleErrors(error.response));
            },
            onObjectLoaded(object) {
                object.traverse((child) => {
                    const split = child.name.split('_');

                    if (split.length !== 3) {
                        return;
                    }

                    const id = split[2];
                    child.material.color.setHex(0xFFFFFF);

                    const formulas = this.object.formulas.filter(formula => formula.revitId === id);

                    if (formulas.length === 0) {
                        return;
                    }

                    child.material.color.setHex(0xC33C86);
                });
            },
            onObjectClicked(object) {
                const id = object.object.name.split('_')[2];
                object.formulas = this.object.formulas.filter(formula => formula.revitId === id);

                this.$refs.objectFormulaPanel.open(object);
            },
            storeObjectFormula(object) {
                const { revitId } = object.formulas[0];

                const formulas = this.object.formulas.filter(formula => formula.revitId !== revitId);

                object.formulas.forEach((formula) => {
                    formulas.push(formula);
                });

                this.$http.post(`/revit-objects/${this.object.id}/formulas`, { formulas })
                    .then(() => {
                        object.reference.object.material.color.setHex(0xC33C86);
                    })
                    .catch(error => this.handleErrors(error.response));
            },
        },
        computed: {
            updatedAt: {
                get() {
                    return this.object.updatedAt;
                },
                set(date) {
                    this.object.updatedAt = date;
                },
            },
        },
    };
</script>
