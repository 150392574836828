<template>
    <div @click="htmlClicked"
         v-html="html"
         v-if="showHtml" />
    <input :value="value"
           @blur="inputBlurred"
           @change="inputBlurred"
           @input="$emit('input', $event.target.value)"
           ref="inputField"
           type="text"
           v-else>
</template>

<script>
    import escape from 'lodash/escape';

    export default {
        name: 'ClickToInput',
        props: {
            tag: {
                required: false,
                type: String,
                default() {
                    return 'p';
                },
            },
            modifier: {
                required: false,
                type: Function,
                default(value) {
                    return value;
                },
            },
            value: {},
        },
        data() {
            return {
                showHtml: true,
            };
        },
        methods: {
            htmlClicked() {
                this.showHtml = !this.showHtml;
                this.$nextTick(() => {
                    this.$refs.inputField.focus();
                });
            },
            inputBlurred() {
                this.showHtml = true;
            },
        },
        computed: {
            html: {
                get() {
                    const value = this.modifier(this.value);

                    return `<${this.tag}>${escape(value)}</${this.tag}>`;
                },
            },
        },
    };
</script>
